<template>
  <div>
    <h4>Toplam Siparişler</h4>
    <p>Canlı olarak TY üzerinden veri çeker. 14 günden eski veriyi çekmez.</p>
    <div style="margin-top: 8px; margin-bottom: 8px;">
      <label for="startDate">Başlangıç Tarihi:</label>
      <input type="date" id="startDate" v-model="startDate" class="datepicker-input">
      <label for="endDate">Bitiş Tarihi:</label>
      <input type="date" id="endDate" v-model="endDate" class="datepicker-input">
      <button @click="fetchOrders">Tarih Aralığını Uygula</button>
    </div>

    <div v-if="orders.length == 0" style="margin-top: 36px;">
      <p>Seçilen tarih aralığında kayıt bulunmamaktadır.</p>
    </div>

    <table v-else border="1">
      <thead>
        <tr>
          <th style="color: black;">Toplam Sipariş Adedi</th>
          <th style="color: black;">Toplam Sipariş Tutarı</th>
          <th style="color: black;">Toplam Maliyet</th>
          <th style="color: black;">Toplam Kargo Ücreti</th>
          <th style="color: black;">Toplam Komisyon</th>
          <th style="color: black;">Toplam Net Kar</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{{ orders.length }}</td>
          <td>{{ formatCurrency(totalOrderPrice) }} TRY</td>
          <td>{{ formatCurrency(totalCost) }} TRY</td>
          <td>{{ formatCurrency(totalCargo) }} TRY</td>
          <td>{{ formatCurrency(totalCommission) }} TRY</td>
          <td>{{ formatCurrency(totalNetProfit) }} TRY</td>
        </tr>
      </tbody>
    </table>

    <v-simple-table v-if="orders.length > 0" style="margin-top: 8px;">
      <thead>
        <tr>
          <th style="color: black; text-align: center;">Sipariş Numarası</th>
          <th style="color: black; text-align: center;">Sipariş Tarihi</th>
          <th style="color: black; text-align: center;">Ürün Adı</th>
          <th style="color: black; text-align: center;">Adet</th>
          <th style="color: black; text-align: center;">Satış Fiyatı</th>
          <th style="color: black; text-align: center;">Maliyet</th>
          <th style="color: black; text-align: center;">Kargo</th>
          <th style="color: black; text-align: center;">Komisyon</th>
          <th style="color: black; text-align: center;">Toplam Tutar</th>
          <th style="color: black; text-align: center;">Net Kâr</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="order in orders" :key="order.id">
          <td>{{ order.orderNumber }}</td>
          <td>{{ formatOrderDate(order.orderDate) }}</td>
          <td style="text-align: start;">
            <ul>
              <li v-for="line in order.lines" :key="line.id">{{ line.productName }}</li>
            </ul>
          </td>
          <td>
            <ul>
              <li v-for="line in order.lines" :key="line.id">{{ line.quantity }}</li>
            </ul>
          </td>
          <td>
            <ul>
              <li v-for="line in order.lines" :key="line.id">{{ formatCurrency(line.price) }}</li>
            </ul>
          </td>
          <td>
            <ul>
              <li v-for="line in order.lines" :key="line.id">{{ formatCurrency(line.cost) }}</li>
            </ul>
          </td>
          <td>{{ formatCurrency(calculateCargo(order.totalPrice)) }}</td>
          <td>
            <ul>
              <li v-for="line in order.lines" :key="line.id">{{ formatCurrency(calculateCommissionInTL(line)) }}</li>
            </ul>
          </td>
          <td>{{ formatCurrency(order.totalPrice) }}</td>
          <td>{{ calculateNetProfit(order) }}</td>
        </tr>
      </tbody>
      <!-- Excel İndir butonu filtreleme işleminden sonra görünür -->
    </v-simple-table>

    <div v-if="showExcelButton" class="download-button">
      <v-btn color="primary" @click="downloadExcel" block>Excel İndir</v-btn>
    </div>

    <v-dialog v-model="loading" persistent width="300">
      <v-card>
        <v-card-text class="d-flex flex-column align-center justify-center" style="height: 200px;">
          <v-progress-circular indeterminate color="primary"></v-progress-circular>
          <p>Lütfen bekleyin...</p>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import ExcelJS from 'exceljs';
import { API_TY_AUTH, API_PROXY_SERVER, API_PROXY_TEST_SERVER } from '../../../secret.json'

export default {
  data() {
    return {
      orders: [],
      totalOrderPrice: 0,
      totalNetProfit: 0,
      totalCommission: 0,
      totalCargo: 0,
      totalCost: 0,
      loading: false,
      startDate: '', // Başlangıç tarihi için değişken
      endDate: '', // Bitiş tarihi için değişken
      showExcelButton: false, // Excel butonunu göstermek için veri
      isFiltered: false,
      noData: false
    };
  },
  mounted() {
    // this.fetchOrders();
  },
  methods: {
    fetchOrders() {
      this.loading = true; // Show loading popup
      this.isFiltered = true;
      this.showExcelButton = true;
      const baseUrl = API_PROXY_SERVER;
      const baseUrlTest = API_PROXY_TEST_SERVER;
      const startDate = new Date(this.startDate);
      const endDate = new Date(this.endDate);

      const startDateFilter = new Date(this.startDate);
      startDateFilter.setDate(startDateFilter.getDate() - 7); // Subtract 7 days
      startDateFilter.setHours(0, 0, 0, 0);
      startDateFilter.setHours(startDateFilter.getHours() + 3);

      const endDateFilter = new Date(this.endDate);
      endDateFilter.setDate(endDateFilter.getDate() + 7); // Add 7 days
      endDateFilter.setHours(23, 59, 59, 0);
      endDateFilter.setHours(endDateFilter.getHours() + 3);

      // Adjusting startDate to 00:00:00 and then to Istanbul time by adding 3 hours
      startDate.setHours(0, 0, 0, 0);
      startDate.setHours(startDate.getHours() + 3);

      // Adjusting endDate to 23:59:59 and then to Istanbul time by adding 3 hours
      endDate.setHours(23, 59, 59, 0);
      endDate.setHours(endDate.getHours() + 3);

      const startDateTime = startDate.getTime();
      const endDateTime = endDate.getTime();
      const startDateTimeFilter = startDateFilter.getTime();
      const endDateTimeFilter = endDateFilter.getTime();
      const size = 200; // Items per page
      const orderByDirection = 'DESC';
      const firebaseUrl = 'https://kitpanel-33ba2-default-rtdb.firebaseio.com/ty_data';

      // Function to fetch orders for a specific page
      const fetchOrdersPage = (page) => {
        const url = `${baseUrl}?startDate=${startDateTimeFilter}&endDate=${endDateTimeFilter}&size=${size}&page=${page}&orderByDirection=${orderByDirection}`;

        return fetch(url, {
          headers: {
            'Authorization': API_TY_AUTH
          }
        })
        .then(response => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.json();
        })
        .then(data => {
          return {
            orders: data.content,
            totalPages: data.totalPages
          };
        })
        .catch(error => {
          console.error(`Error fetching orders for page ${page}:`, error);
          return { orders: [], totalPages: 0 };
        });
      };

      // Function to check if an order already exists in Firebase
      // const orderExistsInFirebase = (orderNumber) => {
      //   return fetch(`${firebaseUrl}/${orderNumber}.json`)
      //     .then(response => response.json())
      //     .then(existingOrder => existingOrder !== null)
      //     .catch(error => {
      //       console.error(`Error checking order ${orderNumber}:`, error);
      //       return false;
      //     });
      // };

      // Function to save an order to Firebase
      // const saveOrderToFirebase = (order) => {
      //   const orderNumber = order.orderNumber;
      //   return fetch(`${firebaseUrl}/${orderNumber}.json`, {
      //     method: 'PUT',
      //     body: JSON.stringify(order),
      //     headers: {
      //       'Content-Type': 'application/json',
      //     }
      //   })
      //   .then(response => {
      //     if (!response.ok) {
      //       throw new Error('Failed to save order to Firebase');
      //     }
      //     console.log(`Order ${orderNumber} added/updated in the database.`);
      //   })
      //   .catch(error => {
      //     console.error(`Error saving order ${orderNumber}:`, error);
      //   });
      // };

      // Function to fetch all pages
      const fetchAllPages = async () => {
        let allOrders = [];
        let page = 0;
        let totalPages = 5;

        try {
          // Fetch the first page to get the total number of pages
          const firstPageData = await fetchOrdersPage(page);
          allOrders.push(...firstPageData.orders);
          totalPages = firstPageData.totalPages;

          // Fetch remaining pages
          const fetchPagePromises = [];
          for (page = 1; page < totalPages; page++) {
            fetchPagePromises.push(fetchOrdersPage(page));
          }

          // Wait for all page fetches to complete
          const remainingPagesData = await Promise.all(fetchPagePromises);
          remainingPagesData.forEach(pageData => {
            allOrders.push(...pageData.orders);
          });

          // Filter orders based on epoch time
          allOrders = allOrders.filter(order => {
            const orderDate = order.orderDate; // Assuming orderDate is in epoch time
            return orderDate >= startDateTime && orderDate <= endDateTime && order.status !== 'Cancelled';
          });

          // Process orders
          const updatedOrdersPromises = allOrders.map(order => this.fetchCargoCost(order));
          const updatedOrders = await Promise.all(updatedOrdersPromises);

          // Save orders to Firebase
          // for (const order of updatedOrders) {
          //   const exists = await orderExistsInFirebase(order.orderNumber);
          //   if (!exists) {
          //     await saveOrderToFirebase(order);
          //   } else {
          //     console.log(`Order ${order.orderNumber} already exists in the database.`);
          //   }
          // }

          return updatedOrders;
        } catch (error) {
          console.error('Error fetching orders:', error);
          return [];
        }
      };

      // Fetch and process data
      fetchAllPages()
        .then(allOrders => {
          this.orders = allOrders;
          this.calculateTotalOrderPrice();
          this.calculateTotalNetProfit();
          this.calculateTotalCommission();
          this.calculateTotalCargo();
          this.calculateTotalCost();
          this.loading = false; // Hide loading popup after data is loaded
        })
        .catch(error => {
          console.error('Error fetching all orders:', error);
          this.loading = false; // Hide loading popup in case of an error
        });
    },
    fetchCargoCost(order) {
      const promises = order.lines.map(line => {
        const barcode = line.barcode; // Assuming barcode is available in line object
        const cargoUrl = `https://kitpanel-33ba2-default-rtdb.firebaseio.com/costs/trendyol.json`;

        return fetch(cargoUrl)
          .then(response => {
            if (!response.ok) {
              throw new Error('Could not fetch cargo costs');
            }
            return response.json();
          })
          .then(cargoData => {
            const item = cargoData.find(item => item.barcode === barcode);
            if (item) {
              line.cargo = parseFloat(item.cargo); // Parse cargo as float (assuming it's a number)
              line.comission = parseFloat(item.comission); // Parse commission as float (assuming it's a number)
              line.cost = parseFloat(item.cost); // Parse cost as float (assuming it's a number)
            } else {
              line.cargo = 0; // Set cargo to 0 if barcode not found
              line.comission = 0; // Set commission to 0 if barcode not found
              line.cost = 0; // Set cost to 0 if barcode not found
            }
            return line;
          })
          .catch(error => {
            console.error('Error fetching cargo costs:', error);
            line.cargo = 0; // Set cargo to 0 on error
            line.comission = 0; // Set commission to 0 on error
            line.cost = 0; // Set cost to 0 on error
            return line;
          });
      });

      return Promise.all(promises)
        .then(updatedLines => {
          order.lines = updatedLines;
          return order;
        });
    },
    calculateCargo(totalPrice) {
      // Calculate cargo fee based on totalPrice
      if (totalPrice >= 0 && totalPrice <= 125) {
        return 30;
      } else if (totalPrice > 125 && totalPrice <= 200) {
        return 54;
      } else {
        return 60;
      }
    },
    calculateTotalCost() {
      let total = 0;

      this.orders.forEach(order => {
        order.lines.forEach(line => {
          total += parseFloat(line.cost) * parseFloat(line.quantity);
        });
      });

      this.totalCost = total;
    },
    calculateCommission(line) {
      // Calculate commission for a single line item
      return parseFloat(line.price) * parseFloat(line.quantity) * (parseFloat(line.comission) / 100);
    },
    calculateCommissionInTL(line) {
      // Calculate commission in TL for a single line item
      const commissionInTL = this.calculateCommission(line);
      return commissionInTL;
    },
    calculateNetProfit(order) {
      // Calculate net profit for the order
      const totalPrice = parseFloat(order.totalPrice);
      let totalCost = 0;
      let totalCommission = 0;

      order.lines.forEach(line => {
        totalCost += parseFloat(line.cost) * parseFloat(line.quantity);
        totalCommission += parseFloat(this.calculateCommission(line));
      });

      const totalCargo = parseFloat(this.calculateCargo(totalPrice));

      const netProfit = totalPrice - (totalCost + totalCommission + totalCargo);

      return this.formatCurrency(netProfit.toFixed(2)) 
    },
    calculateTotalOrderPrice() {
      let total = 0;
      this.orders.forEach(order => {
        total += parseFloat(order.totalPrice);
      });
      this.totalOrderPrice = total;
    },
    calculateTotalNetProfit() {
      let totalCost = 0;
      let totalCommission = 0;
      let totalCargo = 0;

      this.orders.forEach(order => {
        order.lines.forEach(line => {
          totalCost += parseFloat(line.cost) * parseFloat(line.quantity);
          totalCommission += parseFloat(this.calculateCommission(line));
        });
        totalCargo += parseFloat(this.calculateCargo(parseFloat(order.totalPrice)));
      });

      const totalNetProfit = this.totalOrderPrice - (totalCost + totalCommission + totalCargo);
      this.totalNetProfit = totalNetProfit;
    },
    calculateTotalCommission() {
      let total = 0;

      this.orders.forEach(order => {
        order.lines.forEach(line => {
          total += parseFloat(line.price) * parseFloat(line.quantity) * (parseFloat(line.comission) / 100);
        });
      });

      this.totalCommission = total;
    },
    calculateTotalCargo() {
      let total = 0;

      this.orders.forEach(order => {
        // Ensure totalPrice is converted to a number before passing it to calculateCargo
        const totalPrice = parseFloat(order.totalPrice); // Convert to number
        if (!isNaN(totalPrice)) {
          total += this.calculateCargo(totalPrice);
        } else {
          console.error(`Invalid totalPrice encountered: ${order.totalPrice}`);
          // Handle the case where totalPrice is not a valid number
          // For example, decide whether to skip this order or set a default value
        }
      });

      this.totalCargo = total;
    },
    formatCurrency(value) {
      // Format number as currency (Turkish Lira)
      const formatter = new Intl.NumberFormat('tr-TR', {
        //style: 'currency',
        //currency: 'TRY'
      });
      return formatter.format(value);
    },
    formatOrderDate(date) {
      date = new Date(date); // Ensure date is a Date object
      date.setHours(date.getHours() - 3); // Adjust for Istanbul time
      // Format order date to display
      const options = {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        // hour: 'numeric',
        // minute: 'numeric',
        // second: 'numeric',
        //timeZone: 'Europe/Istanbul' // Set timeZone option to Istanbul timezone
      };

      return new Intl.DateTimeFormat('tr-TR', options).format(date);
    },
    async sendEmail() {
      try {
        // Gün, saat ve dakika bilgilerini al
        const now = new Date();
        const formattedDate = now.toLocaleDateString('tr-TR', { day: '2-digit', month: '2-digit', year: 'numeric' });
        const formattedTime = now.toLocaleTimeString('tr-TR', { hour: '2-digit', minute: '2-digit' });
        const subject = `${formattedDate} ${formattedTime} - Günlük Karlılık Tablosu`;

        // Karlılık tablosu verilerini formatla
        const emailText = `
          Günlük Karlılık Tablosu\n\n
          Toplam Sipariş Adedi: ${this.orders.length}\n
          Toplam Sipariş Tutarı: ${this.formatCurrency(this.totalOrderPrice)}\n
          Toplam Maliyet: ${this.formatCurrency(this.totalCost)}\n
          Toplam Kargo Ücreti: ${this.formatCurrency(this.totalCargo)}\n
          Toplam Komisyon: ${this.formatCurrency(this.totalCommission)}\n
          Toplam Net Kar: ${this.formatCurrency(this.totalNetProfit)}
        `;

        // E-posta gönderimi
        const response = await fetch('https://us-central1-kitpanel-proxy-server.cloudfunctions.net/api/mailer', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            to: 'bahadircepni@kithometurkiye.com, batuhancepni@kithometurkiye.com',
            subject: subject, // Başlığa tarih ve saat ekle
            text: emailText,  // Düz metin içeriği ekle
          }),
        });

        if (response.ok) {
          alert('E-posta başarıyla gönderildi.');
        } else {
          alert('E-posta gönderimi başarısız oldu.');
        }
      } catch (error) {
        console.error('E-posta gönderiminde bir hata oluştu:', error);
        alert('E-posta gönderiminde bir hata oluştu.');
      }
    },
    async downloadExcel() {
      this.loading = true; // Show loading popup
      const workbook = new ExcelJS.Workbook();
      const sheet = workbook.addWorksheet('Kârlılık Tablosu');

      // Başlık satırını ekleyelim
      sheet.addRow([
        "Sipariş Numarası", 
        "Sipariş Tarihi", 
        "Ürün Adı", 
        "Adet", 
        "Satış Fiyatı", 
        "Maliyet", 
        "Kargo", 
        "Komisyon (TL)", 
        "Toplam Tutar", 
        "Net Kâr"
      ]);

      // Verileri ekleyelim
      this.orders.forEach(order => {
        order.lines.forEach(line => {
            sheet.addRow([
                order.orderNumber,
                this.formatOrderDate(order.orderDate),
                line.productName,
                line.quantity,
                line.price.toFixed(2), // Fiyatı sabit ondalık sayıya formatla
                line.cost.toFixed(2), // Maliyeti sabit ondalık sayıya formatla
                this.calculateCargo(order.totalPrice).toFixed(2), // Kargo ücretini sabit ondalık sayıya formatla
                this.calculateCommissionInTL(line).toFixed(2), // Komisyonu sabit ondalık sayıya formatla
                order.totalPrice.toFixed(2), // Toplam fiyatı sabit ondalık sayıya formatla
                this.calculateNetProfit(order) // Net karı sabit ondalık sayıya formatla
            ]);
        });
      });
      // Dosyayı kaydedelim
      const buffer = await workbook.xlsx.writeBuffer();
      const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      const filename = 'karlilik_tablosu.xlsx';

      // Excel dosyasını indirelim
      if (navigator.msSaveBlob) { // IE 10+
        navigator.msSaveBlob(blob, filename);
      } else {
        const link = document.createElement('a');
        if (link.download !== undefined) {
          const url = URL.createObjectURL(blob);
          link.setAttribute('href', url);
          link.setAttribute('download', filename);
          link.style.visibility = 'hidden';
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      }
      this.loading = false; // Hide loading popup after download is complete
    }
  }
};
</script>

<style scoped>
table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}
th, td {
  padding: 8px;
  text-align: center;
}
th {
  background-color: #f4f4f4;
}
button {
  margin: 5px;
  padding: 10px;
  border: none;
  background-color: #007bff;
  color: white;
  cursor: pointer;
}
button:hover {
  background-color: #0056b3;
}
a {
  color: #007bff;
  text-decoration: none;
}
a:hover {
  text-decoration: underline;
}
.datepicker-input {
  background-color: white;
  margin: 5px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
ul li {
  margin-bottom: 5px;
  border-bottom: 1px solid #ddd; /* İnce bir çizgi ekleyin */
  padding-bottom: 5px; /* Çizgi ile metin arasında boşluk bırakın */
}
ul li:last-child {
  border-bottom: none; /* Son eleman için çizgi eklemeyin */
}

/* Ürün adı sütununu sola hizala */
table td.product-name {
  text-align: left;
}
</style>